export class NotificationCountResult {
  count: number;
}

export class NotificationResult {
  notificationId: number;
  accountId: number;
  fromUserId: number;
  toUserId: number;
  messageId: number;
  messageCode: string;
  title: string;
  content: string;
  eventCode: string;
  urlDetail: string;
  targetId: number;
  targetTitle: string;
  dateCreated: string;
  dateUpdated: string;
  datediff: string;
}
