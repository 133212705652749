
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from './home-directory/home/home.component';

import { ApplicationRoutes } from './application-routing';

import { SessionService } from './shared-components-services/session.service';
import { HttpService } from './shared-components-services/http.service';
import { AlertService } from './shared-components-services/alert.service';

import { HttpInterceptorService } from './shared-components-services/http-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';

import { MaterialModule } from './material.module';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { PermissionDeniedComponent } from './auth/permission-denied/permission-denied.component';
import { CountoModule } from 'angular2-counto';
import { ChartsModule } from 'ng2-charts';

import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio';

import { QRCodeModule } from 'angularx-qrcode'
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainNavComponent,
    LoginComponent,    
    PermissionDeniedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    BrowserModule,
    RouterModule.forRoot(ApplicationRoutes, { useHash: true }),
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    CountoModule,
    ChartsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    QRCodeModule,
  ],
  exports: [RouterModule, HttpClientModule, BrowserAnimationsModule],
  providers: [
    AuthGuard,
    SessionService,
    HttpService,
    AlertService,
    HttpInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
