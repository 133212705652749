import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionService } from './shared-components-services/session.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private sessionService: SessionService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isLogin = this.sessionService.isLogIn();
    const currentPermission = this.sessionService.getCurrentPermission();
    if (isLogin && isLogin === true) {
      return true;
    } else {
      this.router.navigate(['auth/login']);
    };
  }
}
