
import { ProjectViewModel } from '../view-models/project.viewmodel';

export class ProjectInquiryViewModel {
  public name: string;
  public currentPageNumber: number;
  public currentPageIndex: number;
  public pageSize: number;
  public sortDirection: string;
  public sortExpression: string;
  public totalPages: number;
  public totalProjects: number;
  public projects: Array<ProjectViewModel>;
  public displayedColumns: Array<string>;
  public pageSizeOptions: Array<number>;
}
