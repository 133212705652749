
import { Routes } from '@angular/router';
import { HomeComponent } from './home-directory/home/home.component';
import { AuthGuard } from '../app/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { PermissionDeniedComponent } from './auth/permission-denied/permission-denied.component';

export const ApplicationRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'home/home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/permission-denied', component: PermissionDeniedComponent },
  {
    path: 'report', loadChildren:
      '../app/report/report-management.module#ReportManagementModule'
  },
  {
    path: 'accountmanagement', loadChildren:
      '../app/account-management/account-management.module#AccountManagementModule'
  },
  {
    path: 'inventorymanagement', loadChildren:
      '../app/inventory-management/inventory-management.module#InventoryManagementModule'
  },
  {
    path: 'purchaseordermanagement', loadChildren:
      '../app/purchase-order-management/purchase-order-management.module#PurchaseOrderManagementModule'
  },
  {
    path: 'salesordermanagement', loadChildren:
      '../app/sales-order-management/sales-order-management.module#SalesOrderManagementModule'
  },
  {
    path: 'storemanagement', loadChildren:
      '../app/store-management/store-management.module#StoreManagementModule'
  }
];

