// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // for production cloud server
 accountManagementWebApiUrl: 'http://sibrunei.com:57639/api/',
  notificationHub: 'http://sibrunei.com:57639/',
  inventoryManagementWebApiUrl: 'http://sibrunei.com:57218/api/',

  // For dev visual
/*  accountManagementWebApiUrl: 'http://localhost:57639/api/',
  notificationHub:'http://localhost:57639/',
  inventoryManagementWebApiUrl: 'http://localhost:57218/api/',*/

  // for server iis local
  //accountManagementWebApiUrl: 'http://localhost:85/api/',
 // notificationHub: 'http://localhost:85/',
 // inventoryManagementWebApiUrl: 'http://localhost:86/api/',


};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
