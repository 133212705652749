import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SessionService } from './session.service';
import { UserViewModel } from '../shared-view-models/user.viewmodel';

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

    constructor(private sessionService: SessionService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // modify request

        request = request.clone({
            // setHeaders: {
            //  Authorization: `Bearer ${localStorage.getItem('MY_TOKEN')}`
            // }
        });

        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {

                    const token = event.body.token;

                    if (token !== '' && token !== null && token !== undefined) {
                        localStorage.setItem('token', token);
                        this.sessionService.startSession();
                    }
                }
            }, error => {
                if (error.status === 401) {
                  this.router.navigate(['auth/login']);
                }

            })
        );

    }

}

