import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from '../../shared-components-services/session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../shared-components-services/alert.service';
import { HttpService } from '../../shared-components-services/http.service';
import { WidgetViewModelResponse } from '../view-models/widget-response.viewmodel';
import { WidgetViewModel } from '../view-models/widget.viewmodel';
import { LastOrderViewModelResponse } from '../view-models/last-order-response.viewmodel';
import { LastOrderViewModel } from '../view-models/last-order.viewmodel';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { ProjectInquiryViewModel } from '../view-models/project-inquiry.viewmodel';
import { ProjectViewModel } from '../view-models/project.viewmodel';
import { ProjectInquiryViewModelResponse } from '../view-models/project-inquiry-response.viewmodel';
import { MatSort } from '@angular/material';
import { ProjectViewModelResponse } from '../view-models/project-response.viewmodel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {
  public widget: WidgetViewModel;
  public lastOrders: LastOrderViewModel; 

  // line chart option
  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'Project values' },
  ];
  public lineChartLabels: Label[];
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,0,255,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  // end line chart option

  // bar chart option
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[]; 
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] =
    [
      { data: [], label: 'Project quantity' },
    ];
  // end bar chart option

  public projectInquiryViewModel: ProjectInquiryViewModel;

  constructor(private sessionService: SessionService,
    public datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private httpService: HttpService,) {

    this.projectInquiryViewModel = new ProjectInquiryViewModel();
    this.projectInquiryViewModel.pageSize = 10;

    this.projectInquiryViewModel.displayedColumns = ['name','workorderNumber', 'projectNumber', 'customerName', 'addressLine', 'startDate', 'endDate', 'unitPrice', 'statusName']; //, 'projectId'];
    this.projectInquiryViewModel.pageSizeOptions = [5, 10, 20, 100];

    this.initializeSearch();
  }

  private initializeSearch() {
    this.projectInquiryViewModel.name = '';
    this.projectInquiryViewModel.currentPageNumber = 1;
    this.projectInquiryViewModel.currentPageIndex = 0;
    this.projectInquiryViewModel.totalPages = 0;
    this.projectInquiryViewModel.totalProjects = 0;
    this.projectInquiryViewModel.sortDirection = '';
    this.projectInquiryViewModel.sortExpression = '';
    this.projectInquiryViewModel.projects = new Array<ProjectViewModel>();
  }

  ngOnInit() {
    this.widget = new WidgetViewModel();
    this.lastOrders = new LastOrderViewModel();
    this.getWidgets();
    this.getLastSixMonthOrder();
    this.executeSearch();
  }

  public isAccess(resource: string, event: string): boolean {
    return this.sessionService.isAccess(resource, event);
  }

  private getWidgets() {
    const url = this.sessionService.appSettings.inventoryManagementWebApiUrl + 'dashboard/widgets';
    this.httpService.HttpGet<WidgetViewModelResponse>(url)
      .subscribe((response: WidgetViewModelResponse) => {
        this.getWidgetsSuccess(response);
      }, response => this.getWidgetsFailed(response));
  }

  private getWidgetsSuccess(response: WidgetViewModelResponse) {
    const widget: WidgetViewModel = response.entity;
    this.widget = widget;
  }

  private getWidgetsFailed(error: HttpErrorResponse) {
    const errorResponse: WidgetViewModelResponse = error.error;
    if (error.status > 0) {
      this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
    } else {
      this.alertService.ShowErrorMessage(error.message);
    }
  }

  private getLastSixMonthOrder() {
    const url = this.sessionService.appSettings.inventoryManagementWebApiUrl + 'dashboard/lastSixMonthOrder';
    this.httpService.HttpGet<LastOrderViewModelResponse>(url)
      .subscribe((response: LastOrderViewModelResponse) => {
        this.getLastSixMonthOrdersSuccess(response);
      }, response => this.getLastSixMonthOrdersFailed(response));
  }

  private getLastSixMonthOrdersSuccess(response: LastOrderViewModelResponse) {
    const lastOrders: LastOrderViewModel = response.entity;
    this.lastOrders = lastOrders;

    this.lineChartLabels = this.lastOrders.lastMonths;
    this.lineChartData = [
      { data: this.lastOrders.lastNumbers, label: 'Project quantity' },
    ];

    this.barChartLabels = this.lastOrders.lastMonths;
    this.barChartData = [
      { data: this.lastOrders.lastValues, label: 'Project values' },
    ];    
  }

  private getLastSixMonthOrdersFailed(error: HttpErrorResponse) {
    const errorResponse: LastOrderViewModelResponse = error.error;
    if (error.status > 0) {
      this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
    } else {
      this.alertService.ShowErrorMessage(error.message);
    }
  }

  private executeSearch() {
    const url = this.sessionService.appSettings.inventoryManagementWebApiUrl + 'dashboard/projectinquiry';
    this.httpService.HttpPost<ProjectInquiryViewModelResponse>(url, this.projectInquiryViewModel).
      subscribe((response: ProjectInquiryViewModelResponse) => {
        this.projectInquirySuccess(response);
      }, response => this.projectInquiryFailed(response));
  }

  private projectInquirySuccess(response: ProjectInquiryViewModelResponse) {
    response.entity.forEach(element => {

      if (element.startDate) {
      //  const startDate = element.startDate.toString().substring(0, 10);
       // element.startDate = startDate.substring(5, 7) + '/' + startDate.substring(8, 10) + '/' + startDate.substring(0, 4);
        element.startDate = this.datepipe.transform(element.startDate, 'dd/MM/yyyy');
      }

      if (element.endDate) {
      //  const endDate = element.endDate.toString().substring(0, 10);
       // element.endDate = endDate.substring(5, 7) + '/' + endDate.substring(8, 10) + '/' + endDate.substring(0, 4);
        element.endDate = this.datepipe.transform(element.endDate, 'dd/MM/yyyy');
      }

    });
    this.projectInquiryViewModel.projects = response.entity;
    this.projectInquiryViewModel.totalProjects = response.totalRows;
    this.projectInquiryViewModel.totalPages = response.totalPages;
  }

  private projectInquiryFailed(error: HttpErrorResponse) {
    const errorResponse: ProjectViewModelResponse = error.error;
    if (error.status > 0) {
      this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
    } else {
      this.alertService.ShowErrorMessage(error.message);
    }
  }

  public onPaginateChange(event) {
    this.projectInquiryViewModel.currentPageNumber = event.pageIndex + 1;
    this.projectInquiryViewModel.currentPageIndex = event.pageIndex;
    this.projectInquiryViewModel.pageSize = event.pageSize;
    this.executeSearch();
  }

  public sortData(sort: MatSort) {
    this.projectInquiryViewModel.currentPageNumber = 1;
    this.projectInquiryViewModel.currentPageIndex = 0;
    this.projectInquiryViewModel.sortDirection = sort.direction;
    this.projectInquiryViewModel.sortExpression = sort.active;
    this.executeSearch();
  }

  public resetSearch() {
    // this.lastSearchValue = '';
    this.projectInquiryViewModel.name = '';
    this.initializeSearch();
    this.executeSearch();
  }

  public selectProject(row) {
    const projectId = this.projectInquiryViewModel.projects[row].projectId;
    this.router.navigate(['/report/project-maintenance'], { queryParams: { id: projectId } });
  }

  public showMore() {
    this.router.navigate(['/report/project-inquiry']);
  }

  public linkToSaleOrder() {
    //this.router.navigate(['/inventorymanagement/sales-order-inquiry']);
  }

  public linkToPurchaseOrders() {
    //this.router.navigate(['/inventorymanagement/purchase-order-inquiry']);
  }

  public linkToStock() {
    //this.router.navigate(['/inventorymanagement/stock-inquiry']);
  }
}
