import { UserPermissionViewModel } from "./user-permissions.viewmodel";

export class UserViewModel {
  public userId: number;
  public firstName: string;
  public lastName: string;
  public companyName: string;
  public emailAddress: string;
  public password: string;
  public passwordConfirmation: string;
  public token: string;
  public tokenExpirationDate: Date;
  public isAuthenicated: Boolean;
  public role: string;
  public active: boolean;
  public roleId: number;
  public userPermissionDataTransformations: Array<UserPermissionViewModel>;
}
