import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(private httpClient: HttpClient, private alertService: AlertService) { }

  HttpGet<T>(url: string): any {

    this.alertService.startProgressBar();

    let tokenString = '';

    let httpHeaders = new HttpHeaders();
    const securityToken: string = localStorage.getItem('token');
    if (securityToken !== null) {
      tokenString = `Bearer ${securityToken}`;
      httpHeaders = new HttpHeaders()
        .set('authorization', tokenString)
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'GET')
        .set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe')
        //.set('Access-Control-Allow-Credentials', 'true')
        .set('Content-Type', 'application/json');
    } else {
      httpHeaders = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'GET')
        .set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe')
        //.set('Access-Control-Allow-Credentials', 'true')
        .set('Content-Type', 'application/json');
    }

    return this.httpClient.get<T>(url, { headers: httpHeaders })
      .pipe(
        catchError((err) => this.handleError(err)),
        finalize(() => {
          this.alertService.stopProgressBar();
        })
      );
  }

  HttpPost<T>(url: string, data: any): any {

    this.alertService.startProgressBar();

    let tokenString = '';
    const securityToken: string = localStorage.getItem('token');
    let httpHeaders = new HttpHeaders();
    if (securityToken !== null) {
      tokenString = `Bearer ${securityToken}`;
      httpHeaders = new HttpHeaders()
        .set('authorization', tokenString)
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'POST')
        .set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe')
        //.set('Access-Control-Allow-Credentials', 'true')
        .set('Content-Type', 'application/json');
    } else {
      httpHeaders = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Access-Control-Allow-Methods', 'POST')
        .set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe')
        //.set('Access-Control-Allow-Credentials', 'true')
        .set('Content-Type', 'application/json');
    }

    return this.httpClient.post<T>(url, data, { headers: httpHeaders })
      .pipe(
        catchError((err) => this.handleError(err)),
        finalize(() => {
          this.alertService.stopProgressBar();
        })
    );

  }

  public handleError(error: HttpErrorResponse) {

    console.log('handle error');

    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }

    return throwError(error);
  }
}
