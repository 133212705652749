import { Injectable, EventEmitter } from '@angular/core';
import { AppSettings } from '../shared-models/appsettings.model';
import { UserViewModel } from '../shared-view-models/user.viewmodel';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import * as moment from 'moment';
import 'moment-precise-range-plugin';
import { NotificationCountResult, NotificationResult } from '../shared-models/notification.model';
import { NotificationViewModelResponse } from '../shared-models/notification-response.viewmodel';
import { HttpService } from './http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationResultViewModelResponse } from '../shared-models/notification-result-response.viewmodel';

declare module 'moment' {
  function preciseDiff(start: string | Date | moment.Moment, end: string | Date | moment.Moment, convertToObject?: boolean): any;
}

@Injectable()
export class SessionService {

  public appSettings: AppSettings;
  public userViewModel: UserViewModel;
  public isAuthenicated: Boolean;
  public tokenExpirationDate: any;

  public authenicationEvent: EventEmitter<UserViewModel>;
  public moduleLoadedEvent: EventEmitter<any>;
  private jwtHelperService: JwtHelperService;

  public notification: NotificationCountResult;
  public messages: Array<NotificationResult>;

  public notificationCountEvent: EventEmitter<NotificationCountResult>;
  public notificationResultEvent: EventEmitter<NotificationResultViewModelResponse>;

  constructor(private httpService: HttpService) {
    this.appSettings = new AppSettings();
    this.userViewModel = new UserViewModel();
    this.isAuthenicated = false;
    this.authenicationEvent = new EventEmitter<UserViewModel>();
    this.moduleLoadedEvent = new EventEmitter<any>();
    this.jwtHelperService = new JwtHelperService();
    this.notificationCountEvent = new EventEmitter<NotificationCountResult>();
    this.notificationResultEvent = new EventEmitter<NotificationResultViewModelResponse>();
    this.notification = new NotificationCountResult();
    this.notification.count = 0;
    this.messages = [];
  }

  public setAppSettings(appSettings: AppSettings) {
    this.appSettings = appSettings;
  }

  public isAccess(resource: string, event: string) {
    const currents = JSON.parse(localStorage.getItem("permission"));
    if (currents != null && currents !== undefined) {
      let objectAccess = currents.filter(function (item) {
        return item.resourceName.toLowerCase() == resource.toLowerCase() && item.permissionTypeName.toLowerCase() == event.toLowerCase();
      });
      if (objectAccess != null && objectAccess != undefined && objectAccess.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  public setUserViewModel(userViewModel: UserViewModel) {
    this.userViewModel = userViewModel;
    const token = userViewModel.token;
    this.userViewModel.tokenExpirationDate = this.jwtHelperService.getTokenExpirationDate(token);
    this.isAuthenicated = userViewModel.isAuthenicated;
    this.authenicationEvent.emit(userViewModel);
  }

  public isExpiredSession(): Boolean {
    if (this.userViewModel.token === null || this.userViewModel.token === undefined) {
      return true;
    }
    const isExpired: Boolean = this.jwtHelperService.isTokenExpired(this.userViewModel.token);
    return isExpired;
  }

  public endSession() {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('permission');
    this.userViewModel = new UserViewModel();
    this.userViewModel.isAuthenicated = false;
    this.authenicationEvent.emit(this.userViewModel);
  }

  public startSession() {

    const token: string = localStorage.getItem('token');
    if (token != null && token !== undefined) {
      const jwtHelperService: JwtHelperService = new JwtHelperService();
      const decodedToken = jwtHelperService.decodeToken(token);

      this.userViewModel = new UserViewModel();
      this.userViewModel.token = token;
      this.userViewModel.firstName = decodedToken.given_name;
      this.userViewModel.lastName = decodedToken.nameid;
      this.userViewModel.emailAddress = decodedToken.email;
      this.userViewModel.companyName = decodedToken.name;

      this.userViewModel.isAuthenicated = true;
      this.userViewModel.tokenExpirationDate = jwtHelperService.getTokenExpirationDate(token);
      this.authenicationEvent.emit(this.userViewModel);

      this.getNotificationCount();
      this.getNotificationMessage();
    }
  }

  getExp(): Date {
    const token: string = localStorage.getItem('token');
    if (token != null && token !== undefined) {
      const jwtHelperService: JwtHelperService = new JwtHelperService();
      return jwtHelperService.getTokenExpirationDate(token);
    }
    else {
      return null;
    }  
  }

  getToken(): String {
    const token: string = localStorage.getItem('token');
    return token ? token : "";
  }

  getRole(): String {
    const role: string = localStorage.getItem('token');
    return role ? role : "";
  }

  private checkLogin(): boolean {
    var exp: Date = this.getExp();
    if (exp != null && exp < new Date()) return false;
    var token: String = this.getToken();
    return token && token.length > 0;
  }

  public isLogIn(): boolean {
    const token: string = localStorage.getItem('token');
    if (token != null && token !== undefined) {
      return this.checkLogin(); 
    }
    else {
      return false; 
    }
  }

  public getCurrentPermission() {
    const current = JSON.parse(localStorage.getItem("permission"));
    if (current != null && current !== undefined) {
      return current;
    }
    else {
      return null;
    }
  }

  public getNotificationCount() {
    let url = this.appSettings.accountManagementWebApiUrl + 'notification/notificationcount';
    this.httpService.HttpGet<NotificationViewModelResponse>(url).subscribe((response: NotificationViewModelResponse) => {
      this.notification = response.entity;
      this.notificationCountEvent.emit(this.notification);
    }, response => this.getNotificationCountFailed(response));
  }

  private getNotificationCountFailed(error: HttpErrorResponse) {
    const errorResponse: NotificationViewModelResponse = error.error;
    if (error.status > 0) {
      console.log(errorResponse.returnMessage[0]);
    } else {
      console.log(error.message);
    }
  }

  public getNotificationMessage() {
    let url = this.appSettings.accountManagementWebApiUrl + 'notification/notificationresult';
    this.httpService.HttpGet<NotificationResultViewModelResponse>(url).subscribe((response: NotificationResultViewModelResponse) => {
      this.messages = response.entity;
      if (this.messages.length > 0) {
        let date = new Date();
        this.messages.forEach(it => {
          it.datediff = moment.preciseDiff(it.dateCreated, moment());
        });
      }
      response.entity = this.messages;
      this.notificationResultEvent.emit(response);
    }, response => this.getNotificationMessageFailed(response));
  }

  private getNotificationMessageFailed(error: HttpErrorResponse) {
    const errorResponse: NotificationResultViewModelResponse = error.error;
    if (error.status > 0) {
      console.log(errorResponse.returnMessage[0]);
    } else {
      console.log(error.message);
    }
  }
}

