
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Component, ElementRef, OnInit } from '@angular/core';
import { AppSettings } from './shared-models/appsettings.model';
import { SessionService } from './shared-components-services/session.service';
import { AlertService } from './shared-components-services/alert.service';
import { UserViewModel } from './shared-view-models/user.viewmodel';
import { environment } from '../environments/environment';
import { Message } from './shared-view-models/message.viewmodel';
import * as signalR from '@microsoft/signalr';
import { HttpService } from './shared-components-services/http.service';
import { NotificationCountResult, NotificationResult } from './shared-models/notification.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationViewModelResponse } from './shared-models/notification-response.viewmodel';
import { NotificationResultViewModelResponse } from './shared-models/notification-result-response.viewmodel';
import * as moment from 'moment';
import 'moment-precise-range-plugin';

declare module 'moment' {
  function preciseDiff(start: string | Date | moment.Moment, end: string | Date | moment.Moment, convertToObject?: boolean): any;
}

export interface MenuItem {
  title: string;
  icon: string;
  links: {
    title: string,
    link: string,
    resources: string,
    action: string,
    icon: string;
  }[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public title = '';
  public isAuthenicated: Boolean;
  public firstName: string;
  public lastName: string;
  public tokenExpirationDate: any;
  public showProgressBar: Boolean;
  public iterations = 0;
  public sessionId: any;
  public runningMonitor: boolean;
  public hideMenuBar: boolean;

  public notification: NotificationCountResult;
  public messages: Array<NotificationResult>;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private alertService: AlertService,
    private httpService: HttpService,
    private sessionService: SessionService) {

    this.showProgressBar = false;
    this.sessionId = 0;
    this.runningMonitor = false;
    this.hideMenuBar = true;

    this.notification = new NotificationCountResult();
    this.notification.count = 0;
    this.messages = [];

    this.alertService.progressBarUIEvent.subscribe(event => this.updateProgressBar(event));
    this.sessionService.authenicationEvent.subscribe(event => this.authenicationEvent(event));
    this.sessionService.moduleLoadedEvent.subscribe(event => this.moduleLoadedEvent(event));
    const appSettings = new AppSettings();

    appSettings.accountManagementWebApiUrl = environment.accountManagementWebApiUrl;
    appSettings.inventoryManagementWebApiUrl = environment.inventoryManagementWebApiUrl;
    appSettings.notificationHub = environment.notificationHub;

    sessionService.setAppSettings(appSettings);
    this.isAuthenicated = sessionService.isAuthenicated;
    sessionService.startSession();

    this.sessionService.notificationCountEvent.subscribe(event => this.notificationCountEvent(event));
    this.sessionService.notificationResultEvent.subscribe(event => this.notificationResultEvent(event));
  }

  ngOnInit() {
    let url = this.sessionService.appSettings.notificationHub + 'notify';   
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(url)
      .build();

    connection.start().then(function () {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });

    connection.on("BroadcastMessage", () => {
      this.getNotificationCount();
      this.getNotificationMessage();
    });
  }

  getNotificationCount() {
    let url = this.sessionService.appSettings.accountManagementWebApiUrl + 'notification/notificationcount';
    this.httpService.HttpGet<NotificationViewModelResponse>(url).subscribe((response: NotificationViewModelResponse) => {
      this.notification = response.entity;
    }, response => this.getNotificationCountFailed(response));
  }

  private getNotificationCountFailed(error: HttpErrorResponse) {
    const errorResponse: NotificationViewModelResponse = error.error;
    if (error.status > 0) {
      this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
    } else {
      this.alertService.ShowErrorMessage(error.message);
    }
  }

  getNotificationMessage() {
    let url = this.sessionService.appSettings.accountManagementWebApiUrl + 'notification/notificationresult';
    this.httpService.HttpGet<NotificationResultViewModelResponse>(url).subscribe((response: NotificationResultViewModelResponse) => {
      this.messages = response.entity;
      if (this.messages.length > 0) {
        let date = new Date();
        this.messages.forEach(it => {
          it.datediff = moment.preciseDiff(it.dateCreated, moment());
        });
      }
    }, response => this.getNotificationMessageFailed(response));
  }

  private getNotificationMessageFailed(error: HttpErrorResponse) {
    const errorResponse: NotificationResultViewModelResponse = error.error;
    if (error.status > 0) {
      this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
    } else {
      this.alertService.ShowErrorMessage(error.message);
    }
  }

  public deleteNotifications() {
    let url = this.sessionService.appSettings.accountManagementWebApiUrl + 'notification/deletenotifications';
    this.httpService.HttpPost<any>(url, null)
      .subscribe((response: boolean) => {
        if (response["entity"] === true) {
          this.messages = [];
          this.notification.count = 0;
        }
      }, (error: any) => this.alertService.ShowErrorMessage(error));    
  }

  private notificationCountEvent(notificationCountResult: NotificationCountResult) {
    this.notification.count = notificationCountResult.count;
  }

  private notificationResultEvent(notificationResultViewModelResponse: NotificationResultViewModelResponse) {
    this.messages = notificationResultViewModelResponse.entity;
  }

  menuItems: MenuItem[] = [
    {
      title: 'Projects Summary',
      icon: 'fas fa-university',
      links: [
        {
          title: 'Projects ',
          link: '/report/project-inquiry',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRF Entry',
          link: '/report/all-project-quotation',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRF Authorization',
          link: '/report/all-project-requisition',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Purchases',
          link: '/report/all-project-purchase',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MIF',
          link: '/report/all-project-issues',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRN',
          link: '/report/all-project-return',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Sub-contract ',
          link: '/report/all-project-subcontract',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Manpower',
          link: '/report/all-project-manpower',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'PCV',
          link: '/report/all-project-pettycash',
          resources: 'REPORT',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
      ]
    },
    {
      title: 'Director Authorization',
      icon: 'fab fa-redhat',
      links: [
        {
          title: 'Dashboard',
          link: '/inventorymanagement/customer-sales',
          resources: 'INVENTORY',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Project Pending',
          link: '/inventorymanagement/project-inquiry',
          resources: 'INVENTORY',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRF Entry Pending',
          link: '/inventorymanagement/sales-order-inquiry',
          resources: 'INVENTORY',
          action: 'view_sale_order',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRF Authorization Pending',
          link: '/inventorymanagement/requisition-inquiry',
          resources: 'INVENTORY',
          action: 'manage_requisition',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Purchase order Pending',
          link: '/inventorymanagement/purchase-order-inquiry',
          resources: 'INVENTORY',
          action: 'view_purchase_order',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MIF Pending',
          link: '/inventorymanagement/issue-inquiry',
          resources: 'INVENTORY',
          action: 'view_issue',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRN Pending',
          link: '/inventorymanagement/return-inquiry',
          resources: 'INVENTORY',
          action: 'view_purchase_order',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Subcontract Pending',
          link: '/inventorymanagement/subcontract-inquiry',
          resources: 'INVENTORY',
          action: 'view_subcontract',
          icon: 'fas fa-genderless'
        },
        {
          title: 'PCV Pending',
          link: '/inventorymanagement/pettycash-inquiry',
          resources: 'INVENTORY',
          action: 'view_purchase_order',
          icon: 'fas fa-genderless'
        },
      ]
    },
    {
      title: 'Project management',
      icon: 'fas fa-feather-alt',
      links: [
        {
          title: 'My projects',
          link: '/salesordermanagement/project-inquiry',
          resources: 'SALE',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Subcontract',
          link: '/salesordermanagement/subcontract-inquiry',
          resources: 'SALE',
          action: 'view_subcontract',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Manpower',
          link: '/salesordermanagement/project-manpower-management',
          resources: 'SALE',
          action: 'view_manpower',
          icon: 'fas fa-genderless'
        }
      ]
    },
    {
      title: 'Purchases',
      icon: 'fas fa-layer-group',
      links: [
        {
          title: 'Purchase Order Status',
          link: '/purchaseordermanagement/purchase-order-inquiry',
          resources: 'PURCHASE',
          action: 'view_purchase',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRN Status',
          link: '/purchaseordermanagement/return-inquiry',
          resources: 'PURCHASE',
          action: 'view_purchase',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Supplier',
          link: '/purchaseordermanagement/supplier-inquiry',
          resources: 'PURCHASE',
          action: 'manage_supplier',
          icon: 'fas fa-genderless'
        },
      ]
    },
    {
      title: 'Inventory',
      icon: 'fas fa-warehouse',
      links: [
        {
          title: 'Store',
          link: '/storemanagement/product-inquiry',
          resources: 'STORE',
          action: 'view_product',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MRF Authorization',
          link: '/storemanagement/requisition-inquiry',
          resources: 'STORE',
          action: 'verify_requisition',
          icon: 'fas fa-genderless'
        },
        {
          title: 'MIF Listing',
          link: '/storemanagement/issue-inquiry',
          resources: 'STORE',
          action: 'manage_issue',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Item Category',
          link: '/storemanagement/category-inquiry',
          resources: 'STORE',
          action: 'view_product',
          icon: 'fas fa-genderless'
        },
      ]
    },
/*    {
      title: 'CS & M',
      icon: 'fas fa-store',
      links: [
        {
          title: 'Dashboard',
          link: '/inventorymanagement/customer-sales',
          resources: 'INVENTORY',
          action: 'view_project',
          icon: 'fas fa-genderless'
        },
      ]
    },*/
    {
      title: 'Forms',
      icon: 'fab fa-wpforms',
      links: [
        {
          title: 'Toolbox Talk',
          link: '/inventorymanagement/forms/toolbox-inquiry',
          resources: 'TECHNICIAN',
          action: 'view_toolbox',
          icon: 'fas fa-genderless'
        },
      ]
    }, 
    {
      title: 'Accounts',
      icon: 'fas fa-street-view',
      links: [
        {
          title: 'User',
          link: 'accountmanagement/users',
          resources: 'ACCOUNT',
          action: 'view_users',
          icon: 'fas fa-genderless'
        },
        {
          title: 'Role',
          link: 'accountmanagement/roles',
          resources: 'ACCOUNT',
          action: 'view_role',
          icon: 'fas fa-genderless'
        }
      ]
    }
  ];

  activeMenu?: MenuItem;

  toggle(menu: MenuItem): void {
    this.activeMenu = this.activeMenu === menu ? void 0 : menu;
  }

  isActive(menu: MenuItem): boolean {
    return this.activeMenu === menu;
  }

  public isAccess(resource: string, event: string): boolean {
    return this.sessionService.isAccess(resource, event);
  }

  private moduleLoadedEvent(event: any) {
    this.hideMenuBar = true;
  }

  private updateProgressBar(event: Boolean) {
    this.showProgressBar = event;
  }

  private authenicationEvent(userViewModel: UserViewModel) {

    this.isAuthenicated = userViewModel.isAuthenicated;
    this.firstName = userViewModel.firstName;
    this.lastName = userViewModel.lastName;

    this.tokenExpirationDate = userViewModel.tokenExpirationDate;

    if (this.isAuthenicated === true && this.runningMonitor === false) {
      this.runningMonitor = true;
      this.monitorSession();
      this.sessionId = setInterval(() => {
        this.monitorSession();
      }, 5000);
    } else {
      if (this.isAuthenicated === false && this.runningMonitor === true) {
        this.clearSessionInterval();
      }
    }
  }

  public toggleNavBar() {
    if (this.hideMenuBar === false) {
      this.hideMenuBar = true;
    } else {
      this.hideMenuBar = false;
    }
  }

  private monitorSession() {
    const isExpiredSession = this.sessionService.isExpiredSession();
    if (isExpiredSession) {
      this.isAuthenicated = false;
      this.clearSessionInterval();
      this.logout();
    } else {
      this.isAuthenicated = true;
    }
    this.iterations++;
  }

  public logout() {
    this.sessionService.endSession();
    this.router.navigate(['auth/login']);
  }

  private clearSessionInterval() {
    if (this.sessionId !== 0) {
      clearInterval(this.sessionId);
      this.sessionId = 0;
    }
    this.runningMonitor = false;
  }

  public getRole() {
    if (localStorage.getItem('role')) {
      return localStorage.getItem('role');
    }
    else {
      return "";
    }
  }
}
